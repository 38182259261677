export const COMPANYCONFIGKEY = 'company-config';

export const USERLASTCATEGORIESKEY = 'user-categories';

export const LASTPROMPTVIEWED = 'last-prompt';

export const USERKEY = 'user';

export const LEGACYUSERKEY = 'usuario';

export const USERTRANSLATIONKEY = 'user-translation';

export const SW_CLEAR = 'sw-clear';
