import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from 'react-router-dom';
import DownloadFeed from '../screens/DownloadFeed';
import ErrorPage from '../screens/ErrorPage';
import Feed from '../screens/Feed';
import LinkFeed from '../screens/LinkFeed';
import News, { newsLoader } from '../screens/News';
import NewsFeed from '../screens/NewsFeed';
import Podcast, { podcastLoader } from '../screens/Podcast';
import PodcastFeed from '../screens/PodcastFeed';
import UnitFeed from '../screens/UnitFeed';
import Video, { videoLoader } from '../screens/Video';
import VideoFeed from '../screens/VideoFeed';
import FixedPage from '../components/FixedPage';
import Login from '../screens/Login';
import { ProtectedRoute } from '../components/ProtectedRoute';
import { useUserAuthContext } from '../context/userAuthContext';
import FavoritesFeed from '../screens/FavoritesFeed';
import TVOnline from '../screens/TVOnline';
import SearchFeed from '../screens/SearchFeed';
import { SendPageView } from '../components/SendPageView';
import TermsOfUse from '../screens/TermsOfUsePage';
import Profile from '../screens/Profile';
import ContactUs from '../screens/ContactUs';
import ChangePassword from '../screens/ChangePassword';
import FirstTime from '../screens/FirstTime';
import Client from '../screens/Client';

function AppRoutes() {
    const { user } = useUserAuthContext();

    const router = createBrowserRouter([
        {
            path: '/',
            element: <Navigate to="/app" />,

        },
        {
            path: 'cliente/*',
            element: <Client />,
            errorElement: <ErrorPage />,
        },
        {
            path: '/app',
            children: [
                {
                    path: '',
                    element: (
                        <ProtectedRoute>
                            <Navigate to="feed" />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    element: (
                        <ProtectedRoute>
                            <SendPageView>
                                <FixedPage />
                            </SendPageView>
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                    children: [
                        {
                            path: 'feed',
                            element: (
                                <ProtectedRoute>
                                    <Feed />
                                </ProtectedRoute>
                            ),
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'unit',
                            element: (
                                <ProtectedRoute>
                                    <UnitFeed />
                                </ProtectedRoute>
                            ),
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'news',
                            element: (
                                <ProtectedRoute>
                                    <NewsFeed />
                                </ProtectedRoute>
                            ),
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'podcast',
                            element: (
                                <ProtectedRoute>
                                    <PodcastFeed />
                                </ProtectedRoute>
                            ),
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'link',
                            element: (
                                <ProtectedRoute>
                                    <LinkFeed />
                                </ProtectedRoute>
                            ),
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'download',
                            element: (
                                <ProtectedRoute>
                                    <DownloadFeed />
                                </ProtectedRoute>
                            ),
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'video',
                            element: (
                                <ProtectedRoute>
                                    <VideoFeed />
                                </ProtectedRoute>
                            ),
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'favorites',
                            element: (
                                <ProtectedRoute>
                                    <FavoritesFeed />
                                </ProtectedRoute>
                            ),
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'search',
                            element: (
                                <ProtectedRoute>
                                    <SearchFeed />
                                </ProtectedRoute>
                            ),
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'tvonline',
                            element: (
                                <ProtectedRoute>
                                    <TVOnline />
                                </ProtectedRoute>
                            ),
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'news/:contentId/:slug',
                            element: (
                                <ProtectedRoute>
                                    <News />
                                </ProtectedRoute>
                            ),
                            loader: newsLoader,
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'video/:contentId/:slug',
                            element: (
                                <ProtectedRoute>
                                    <Video />
                                </ProtectedRoute>
                            ),
                            loader: videoLoader,
                            errorElement: <ErrorPage />,
                        },
                        {
                            path: 'podcast/:contentId/:slug',
                            element: (
                                <ProtectedRoute>
                                    <Podcast />
                                </ProtectedRoute>
                            ),
                            loader: podcastLoader,
                            errorElement: <ErrorPage />,
                        },
                    ],
                },
                {
                    path: 'terms-of-use',
                    element: (
                        <ProtectedRoute>
                            <TermsOfUse />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: 'first-time',
                    element: (
                        <ProtectedRoute isFirstTime>
                            <FirstTime />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: 'profile',
                    element: (
                        <ProtectedRoute>
                            <Profile />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: 'contact-us',
                    element: (
                        <ProtectedRoute>
                            <ContactUs />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: 'change-password',
                    element: (
                        <ProtectedRoute>
                            <ChangePassword />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: 'login',
                    element: user ? (
                        <Navigate to="/app/feed" />
                    ) : (
                        <SendPageView>
                            <Login />
                        </SendPageView>
                    ),
                    errorElement: <ErrorPage />,
                },
            ],
        },
    ]);
    return <RouterProvider router={router} />;
}

export default AppRoutes;
